import {
  Grid,
  Stack,
  Box,
  Typography,
  Container,
  List,
  ListItem,
} from "@mui/material";
import Logo from "../assets/images/WhatsInTheBag.webp";
import ImageBox from "../components/imagebox/ImageBox";
import CircleIcon from "@mui/icons-material/Circle";

const breakdown = [
  {
    title: "Nitrogen (N)",
    info: [
      "Nitrogen mixed with proper watering will make your lawn have the lush green color that is desired.",
      "Nitrogen fuels leaf and stem growth.",
      "Nitrogen is the nutrient that tends to deplete the fastest, especially in lawns.",
      "Nitrogen is an essential nutrient for plant growth, development and reproduction. ",
      "Despite nitrogen being one of the most abundant elements on earth, nitrogen deficiency is probably the most common nutritional problem affecting plants.",
    ],
  },
  {
    title: "Phosphorus (P)",
    info: [
      "Phosphorus stimulates root growth and seed formation.",
      "Phosphorus is prominent in fertilizers used in fall.",
      "There is a great concern for excessive phosphorus in lakes and rivers from fertilizer.",
      "Many state legislatures have passed or are trying to pass statewide laws that restrict the application of phosphorus fertilizer to established turf. ",
      "A brief summary of the law is as follows:",
      "Lawn fertilizer use is restricted to 0% phosphate content. Exceptions include if a new lawn is being seeded or sodded and only during the first year of establishment or if a soil or tissue test shows a need for P. In those cases, lawn fertilizers with P can be used",
    ],
  },
  {
    title: "Potassium (K)",
    info: [
      "Potassium promotes flowering, fruiting, and disease resistance.",
      "Potassium guards the plant against diseases.",
      "Potassium aids in drought protection and cold tolerance. ",
      "Potassium also serves a role in improving root development and helps in the process of photosynthesis. ",
      "Potassium is held on the surfaces of soil particles and moves little in most soils, though it can gradually move out of the root zone in very sandy soils. ",
      "Where soils are high in native potassium, supplemental potassium fertilization may be unnecessary; however, where soils are low in native potassium, supplemental applications are very important.",
    ],
  },
];

const InTheBag = () => {
  return (
    <Grid container>
      <Grid item xs={12} mt={{ xs: 3, sm: 5 }}>
        <Container>
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Typography align="center" variant="h3">
              Understanding What's In The Bag
            </Typography>

            <Box justifyContent="center" display="flex" mb={3}>
              <ImageBox src={Logo} sx={{ width: { xs: "75%", sm: "50%" } }} />
            </Box>

            <Typography fontWeight="bold" variant="body2">
              32-3-10
            </Typography>

            <Typography
              fontStyle="italic"
              fontWeight="bold"
              mt={1}
              variant="body2"
            >
              32[N] – 3[P] – 10[K]
            </Typography>

            <Typography mt={1}>
              The best way for you to know what kind of fertilizer you need is
              by understanding what is in the bag and what it will do. First, it
              is important to answer this question: What is an analysis? The 3
              numbers on the front of the fertilizer bag are what make up what
              is generally known as “an analysis”. These numbers represent the
              Nitrogen (N), Phosphorus (P) and Potassium (K) levels in the bag.
              The analysis will ALWAYS appear as N-P-K, in that order. Each
              element will do different things for you. Different analysis work
              better for different goals, different seasons and different
              regions of the country.
            </Typography>

            {breakdown.map((info) => {
              return (
                <>
                  <Typography mt={2} fontWeight="bold">
                    {info.title}
                  </Typography>

                  <List dense={true}>
                    {info.info.map((i) => {
                      return (
                        <ListItem>
                          <CircleIcon sx={{ fontSize: "5px", mr: 1 }} />
                          {i}
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              );
            })}

            <Typography mt={1}>
              We hope that proper understanding of each element will go a long
              way in helping you understand which fertilizer would be best for
              your needs.
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

export default InTheBag;
