import { Grid, Stack, Box, Typography, Container } from "@mui/material";
import Logo from "../assets/images/Ice_Melt_FAQ.webp";
import ImageBox from "../components/imagebox/ImageBox";

const qa = [
  {
    q: "Is ice melt (potassium chloride) hazardous? How do I dispose of it?",
    a: "As with many household chemicals, the best thing to do is to use all of the product per the label instructions. In this case, melt snow. However, sometimes this is not possible. Read the instructions to determine what chemicals are in the product. In this case the product contains, potassium chloride which is a type of salt and can be placed in your regular trash and sent to the landfill.",
  },
  {
    q: "How do I remove ice and snow from a wood patio?",
    a: "Apply Paw-Pal or XXXX ice melter, rather than more caustic sodium- or calcium-chloride melters. We strongly caution against using our sodium-based melters, XXXX or XXXX on wood.) Use a plastic-bladed (not metal) snow shovel to remove any snow covering the ice. The shovel can also be used to scrape under edges and lift off ice as it melts. Avoid hard pounding or scraping with the shovel, to prevent damage to the surface or finish on the wood. Apply as small a quantity as possible, scrape gently and then re-apply to the stubborn spots until ice is removed.",
  },
  {
    q: "Is ice melt safe for my dog?",
    a: "Ice melt contains calcium chloride, which is, potentially, corrosive. In all animals and humans, it may cause severe skin and gastrointestinal tract irritation. This depends on the concentration and circumstances of the exposure. Take care not to apply ice melt thickly. As an additional precaution, wipe your dog’s paws off thoroughly if he or she steps on any of the crystals or pellets. An safer alternative is to use ice melt products labeled PetSafe® like our PawPal ice melt.",
  },
];

const IceMeltFAQ = () => {
  return (
    <Grid container>
      <Grid item xs={12} mt={{ xs: 3, sm: 5 }}>
        <Container>
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Typography align="center" variant="h3">
              Ice Melt FAQs
            </Typography>

            <Box justifyContent="center" display="flex" mb={3}>
              <ImageBox src={Logo} sx={{ width: { xs: "75%", sm: "50%" } }} />
            </Box>

            {qa.map((info) => {
              return (
                <>
                  <Typography
                    fontWeight="bold"
                    sx={{
                      typography: { xs: "body1", sm: "h6", md: "h5" },
                    }}
                  >
                    <Typography fontWeight="bold" mt={1}>
                      {info.q}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      typography: { xs: "body2", sm: "body2", md: "h3" },
                    }}
                  >
                    {info.a}
                  </Typography>
                </>
              );
            })}
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

export default IceMeltFAQ;
