import Layout from "./components/Layout";
import { createTheme, ThemeProvider } from "@mui/material";
import Home from "./views/Home";
import Fertilizer from "./views/Fertilizer";
import Education from "./views/Education";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IceMelts from "./views/IceMelts";
import WaterConditioningSalt from "./views/WaterConditioningSalt";
import IceMeltFAQ from "./views/Ice_Melt_FAQ";
import FertilizerFAQ from "./views/Fertilizer_FAQ";
import InTheBag from "./views/InTheBag";
import ScrollToTop from "./components/ScrollToTop";

const theme = createTheme({
  palette: {
    primary: {
      light: "#536cb0",
      main: "#1d4280",
      dark: "#001c53",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#fffa57",
      main: "#ffc717",
      dark: "#c79700",
      contrastText: "#1d4280",
    },
  },
});

function Root() {
  return (
    <Layout>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Fertilizers/*" element={<Fertilizer />} />
        <Route path="/Ice-Melts/*" element={<IceMelts />} />
        <Route
          path="/Water-Conditioning-Salts"
          element={<WaterConditioningSalt />}
        />
        <Route
          path="/Water-Conditioning-Salts/*"
          element={<WaterConditioningSalt />}
        />
        <Route path="/Education" element={<Education />} />
        <Route path="/Ice-Melt-FAQ" element={<IceMeltFAQ />} />
        <Route path="/Fertilizer-FAQ" element={<FertilizerFAQ />} />
        <Route path="/Understanding-Whats-In-The-Bag" element={<InTheBag />} />
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Root />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
