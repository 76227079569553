import { Box } from "@mui/material";

const Divider = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "5px",
        backgroundColor: "#1d4280",
        boxShadow: 10,
        borderRadius: 5,
        my: 3,
      }}
    />
  );
};

export default Divider;
