import { Typography, Container, useMediaQuery } from "@mui/material";
import CustomIceMelts from "../components/CustomIceMelts";

const tab_info = {
  title: "Ice-Melts Products",
  url: "Ice-Melts",
  tabs: [
    {
      value: "Ice-Melts",
      url: "All-Ice-Melts",
      index: 0,
      rows: [{ row: 0 }, { row: 1 }, { row: 2 }],
      row_title: "all_ice_melts",
    },
  ],
};

const IceMelts = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        {!matches && (
          <Typography align="center" variant="h4" mb={5}>
            Ice-Melt Products
          </Typography>
        )}
        <CustomIceMelts tab_info={tab_info} />
      </Container>
    </>
  );
};

export default IceMelts;
