import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Stack,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState, useEffect } from "react";
import Row from "./grid_rows/Index";
import { Link, useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function CustomIceMelts({ tab_info }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [menuValue, setMenuValue] = useState(
    location.pathname.replace("%50", " ")
  );
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
    setMenuValue(location.pathname.replace(/%20/g, ""));
  }, [location]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        background: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          Index: 2,
        }}
      >
        {!matches ? (
          <Tabs value={menuValue} textColor="primary" indicatorColor="primary">
            {tab_info.tabs.map((tab, index) => (
              <Tab
                key={index}
                component={Link}
                to={`/${tab_info.url}/${tab.url}`}
                value={`/${tab_info.url}/${tab.url}`}
                label={
                  <Typography
                    variant="body2"
                    color={menuValue.includes(tab.value) ? "primary" : ""}
                    fontWeight="bold"
                  >
                    {tab.value}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        ) : (
          <>
            <Button
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleClick}
              color="primary"
              sx={{ width: "100%" }}
            >
              <Typography variant="h6">{tab_info.title}</Typography>
              {menuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleClose}
              PaperProps={{ style: { width: "100%" } }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              disableAutoFocusItem
            >
              {tab_info.tabs.map((tabs) => {
                return (
                  <MenuItem
                    key={tabs.index}
                    value={menuValue}
                    index={Number(tabs.index)}
                    sx={{
                      justifyContent: "center",
                    }}
                    component={Link}
                    to={`/${tab_info.url}/${tabs.url}`}
                    onClick={handleClose}
                  >
                    <Typography
                      variant="caption"
                      color={menuValue.includes(tabs.value) ? "primary" : ""}
                      fontWeight="bold"
                    >
                      {tabs.value}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </Box>

      {tab_info.tabs.map((tab) => {
        return (
          <TabPanel
            key={tab.url}
            value={menuValue}
            index={`/${tab_info.url}/${tab.url}`}
          >
            <Typography mb={2} align="center">
              {tab.description}
            </Typography>

            <Box display="flex" justifyContent="center">
              <Stack spacing={2}>
                {tab.rows?.map((row, index) => (
                  <Row
                    key={index}
                    title={tab.row_title}
                    row={row.row}
                    row_title={row.sub_title}
                  />
                ))}
              </Stack>
            </Box>
          </TabPanel>
        );
      })}
    </Box>
  );
}
