import { styled } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material";
import ResponsiveAppBar from "./appbar/AppBar";
import Footer from "./Footer";

const PageComponent = styled("div")({
  position: "relative",
  minHeight: "95VH",
  width: "100%",
});

export default function Layout({ children }) {
  return (
    <Box>
      <CssBaseline />

      <ResponsiveAppBar />

      <PageComponent>{children}</PageComponent>

      <Footer />
    </Box>
  );
}
