import { Box } from "@mui/material";

const ImageBox = ({ alt, sx, src }) => {
  return (
    <>
      <Box component="img" alt={alt} sx={sx} src={src} />
    </>
  );
};

export default ImageBox;
