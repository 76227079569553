import { Typography, Container, useMediaQuery } from "@mui/material";
import ProductTab from "../components/tabs/ProductTabs";

const tab_info = {
  title: "Salt Products",
  url: "Water-Conditioning-Salts",
  tabs: [
    {
      value: "Solina Solar Salt Water Softener",
      url: "Solina-Solar-Salt-Water-Softener",
      description:
        "Bruce Oakley’s Water Conditioning Salt is called Solina.  Solina is available in a 50# bag that will be the highest purity solar salt on the market.  Our solar salt is very clean with a consistent uniformed sizing and contains very little fines which helps prevent any caking problems.",
      index: 0,
      rows: [{ row: 0 }],
      row_title: "solina",
    },
    {
      value: "Pool Salt",
      url: "Pool-Salt",
      description: "",
      index: 1,
      rows: [{ row: 0 }],
      row_title: "pool_salt",
    },
    {
      value: "Bulk Industrial Salt",
      url: "Bulk-Industrial-Salt",
      description: "Contact For Details",
      index: 2,
    },
  ],
};

const IceMelts = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        {!matches && (
          <Typography align="center" variant="h4" mb={5}>
            Salt Products
          </Typography>
        )}

        <ProductTab tab_info={tab_info} />
      </Container>
    </>
  );
};

export default IceMelts;
