import { Stack, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Stack sx={{ backgroundColor: "#094081", py: 3, mt: 3 }} spacing={1} p={1}>
      <Typography align="center" color="secondary">
        Bruce Oakley, Inc. | 3400 Gribble Street, North Little Rock, AR 72114
      </Typography>

      <Typography align="center" color="secondary">
        Office: (501) 945-0875 | Toll Free: (800) 662-0875 | Fax: (501) 945-5722
      </Typography>

      <Typography align="center" color="secondary">
        Copyright © 2020 Bruce Oakley Inc. All rights reserved.
      </Typography>
    </Stack>
  );
};

export default Footer;
