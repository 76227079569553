import * as React from "react";
import {
  Card,
  Grid,
  Tooltip,
  CardContent,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FaxIcon from "@mui/icons-material/Fax";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Contacts from "../../assets/contacts.json";

const ContactGrid = ({ contact }) => {
  return (
    <div style={{ height: "100%" }}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
        }}
        elevation={3}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            align="center"
            sx={{ m: 0 }}
          >
            {contact.name}
          </Typography>
          <Typography
            variant="body1"
            component="div"
            color="text.secondary"
            align="center"
            style={{ fontWeight: 600 }}
          >
            {contact.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            component="div"
          >
            {contact.address}
          </Typography>

          <Stack direction="row" justifyContent="center" spacing={2} mt={1}>
            {contact.contact_work && (
              <Tooltip title={contact.contact_work}>
                <Typography
                  variant="caption"
                  color="primary"
                  align="center"
                  component="a"
                  href={`tel:${contact.contact_work}`}
                >
                  <PhoneIcon sx={{ fontSize: "20px" }} />
                </Typography>
              </Tooltip>
            )}

            {contact.contact_cell && (
              <Tooltip title={contact.contact_cell}>
                <Typography
                  variant="caption"
                  color="primary"
                  align="center"
                  component="a"
                  href={`tel:${contact.contact_cell}`}
                >
                  <PhoneIphoneIcon sx={{ fontSize: "20px" }} />
                </Typography>
              </Tooltip>
            )}

            {contact.contact_fax && (
              <Tooltip title={contact.contact_fax}>
                <Typography
                  variant="caption"
                  color="primary"
                  align="center"
                  component="a"
                  href={`tel:${contact.contact_fax}`}
                >
                  <FaxIcon sx={{ fontSize: "20px" }} />
                </Typography>
              </Tooltip>
            )}

            {contact.email && (
              <Tooltip title={contact.email}>
                <Typography
                  variant="caption"
                  color="primary"
                  align="center"
                  component="a"
                  href={`mailto:${contact.email}`}
                >
                  <EmailIcon sx={{ fontSize: "20px" }} />
                </Typography>
              </Tooltip>
            )}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

const ContactCard = () => {
  return (
    <Box>
      <Grid container sx={{ justifyContent: "center" }} spacing={2}>
        <Grid item xs={12}>
          <Typography align="center" variant="h6" color="primary">
            Contacts
          </Typography>
        </Grid>
        {Contacts.map((contact, index) => (
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            style={{ width: "100%" }}
            key={index}
          >
            <ContactGrid contact={contact} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ContactCard;
