import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid, Stack } from "@mui/material";
import ProductDataTable from "../datatable/ProductDataTable";
import CloseIcon from "@mui/icons-material/Close";
import ImageBox from "../imagebox/ImageBox";

export default function ProductModal({ product, open, handleClose }) {
  const style = {
    position: "absolute",
    top: { xs: "5%", sm: "5%", md: "5%", lg: "50%" },
    left: { xs: "", sm: "5%", md: "", lg: "50%" },
    transform: { xs: "", sm: "", md: "", lg: "translate(-50%, -50%)" },
    width: { xs: "100%", sm: "90%", md: "90%", lg: "50%" },
    height: "90%",
    overflow: "auto",
    backgroundColor: "rgba(255,255,255,.96)",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      {product && (
        <Box sx={style}>
          <CloseIcon
            sx={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }}
            onClick={handleClose}
          />

          <Stack
            direction={{ xs: "column", lg: "row" }}
            display="flex"
            alignItems="center"
            spacing={2}
            justifyContent="center"
          >
            {product.analysis && (
              <Typography
                sx={{
                  backgroundColor: "#36ad2d",
                  color: "#fff",
                  fontSize: "1.5rem",
                  p: 1,
                }}
                align="center"
              >
                {product.analysis}
              </Typography>
            )}

            <Typography variant="h5">
              {product.title}
              {product.subscript && <sup>®</sup>}
            </Typography>
            {product.subtitle && (
              <Typography variant="body2" color="primary">
                <sup>{product.subtitle}</sup>
              </Typography>
            )}
          </Stack>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              display="flex"
              alignItems="center"
              order={{ xs: 2, sm: 1 }}
            >
              <Stack width="100%">
                {product.information && (
                  <Stack spacing={2} mt={{ xs: 0, sm: 5 }}>
                    {product.information.map((info, index) => (
                      <Typography variant="body2" key={index}>
                        {info}
                      </Typography>
                    ))}
                  </Stack>
                )}
                <ProductDataTable product={product} />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={5} p={2} order={{ xs: 1, sm: 2 }}>
              <Stack>
                <ImageBox
                  src={require(`../../assets/images/${product.image_filename}`)}
                  sx={{ display: { xs: "none", sm: "block" } }}
                />

                {product.sds_filename && (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={() => {
                      window.open(
                        require(`../../assets/pdfs/${product.sds_filename}`)
                      );
                    }}
                  >
                    Download SDS File
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
}
