import Marquee from "react-fast-marquee";
import { Box } from "@mui/material";
import Fertilizers from "../../assets/fertilizers.json";
import ProductModal from "../modal/ProductModal";
import { useState } from "react";

const chosen_bags = [
  { title: "premium_blends", description: "27-3-3", row: "0", column: "1" },
  {
    title: "all_ice_melts",
    description: "Melt Man",
    row: "0",
    column: "0",
  },
  {
    title: "commercial_fertilizer",
    description: "10-20-10",
    row: "1",
    column: "4",
  },
  {
    title: "commercial_fertilizer",
    description: "20-10-10",
    row: "0",
    column: "3",
  },
  {
    title: "all_ice_melts",
    description: "arctic_blaster",
    row: "1",
    column: "3",
  },
  { title: "premium_blends", description: "12-22-10", row: "0", column: "0" },
  {
    title: "commercial_fertilizer",
    description: "6-24-24",
    row: "1",
    column: "2",
  },
  { title: "solina", description: "solina", row: "0", column: "0" },
  {
    title: "management_programs",
    description: "Weed_And_Feed_Program_2",
    row: "1",
    column: "1",
  },
];

const ImageBoxComponent = ({
  src,
  width,
  height,
  info,
  setProduct,
  handleModalOpen,
}) => {
  return (
    <Box
      component="img"
      alt={info.title}
      className="MarqueeImage"
      sx={{ height: height ? height : "100%", width: width ? width : 200 }}
      src={src}
      onClick={() => {
        setProduct(info);
        handleModalOpen();
      }}
    />
  );
};

const MarqueeComponent = () => {
  const [product, setProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [play, setPlay] = useState(true);
  const handleModalClose = () => {
    setOpen(false);
    setPlay(true);
  };
  const handleModalOpen = () => {
    setOpen(true);
    setPlay(false);
  };

  return (
    <>
      <Marquee
        pauseOnHover={true}
        play={play}
        style={{ height: "100%", minWidth: "100%" }}
        speed={100}
      >
        {chosen_bags.map((bag, index) => {
          let bag_info = Fertilizers.find(
            (fertilizer) => fertilizer.title === bag.title
          ).rows[bag.row].columns[bag.column];

          return (
            <ImageBoxComponent
              src={require(`../../assets/images/${bag_info.image_filename}`)}
              key={index}
              width={200}
              height="95%"
              info={bag_info}
              setProduct={setProduct}
              handleModalOpen={handleModalOpen}
            />
          );
        })}
      </Marquee>

      {product && (
        <ProductModal
          product={product}
          open={open}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default MarqueeComponent;
