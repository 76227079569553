import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OakleyLogo from "../../assets/images/OakleyLogo.svg";
import ImageBox from "../imagebox/Responsive_Logo";
import { useNavigate, useLocation } from "react-router-dom";

const pages = [
  { name: "Home", path: "/" },
  {
    name: "Fertilizers",
    path: "/Fertilizers/Greenskeepers-Secret-Turf-Management-Programs",
  },
  { name: "Ice-Melts", path: "/Ice-Melts/All-Ice-Melts" },
  {
    name: "Water-Conditioning-Salts",
    path: "/Water-Conditioning-Salts/Solina-Solar-Salt-Water-Softener",
  },
  { name: "Education", path: "/Education" },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  let navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <ImageBox
            src={OakleyLogo}
            width={250}
            height={75}
            r_xs="none"
            r_md="flex"
          />

          <Typography
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          ></Typography>

          <ImageBox
            src={OakleyLogo}
            width={225}
            height={75}
            r_xs="flex"
            r_md="none"
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    backgroundColor: () => {
                      if (
                        location.pathname.includes(page.name) ||
                        (location.pathname === "/" && page.name === "Home")
                      ) {
                        return "#ffc717";
                      } else {
                        return "transparent";
                      }
                    },
                    "&:hover": { backgroundColor: "#ffc717" },
                  }}
                  onClick={() => {
                    navigate(`${page.path}`);
                    handleCloseNavMenu();
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: () => {
                        if (
                          location.pathname.includes(page.name) ||
                          (location.pathname === "/" && page.name === "Home")
                        ) {
                          return "#1d4280";
                        } else {
                          return "black";
                        }
                      },
                      "&:hover": { color: "#1d4280" },
                    }}
                  >
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={() => {
                  navigate(`${page.path}`);
                }}
                sx={{
                  my: 2,
                  color: () => {
                    if (
                      location.pathname.includes(page.name) ||
                      (location.pathname === "/" && page.name === "Home")
                    ) {
                      return "#ffc717";
                    } else {
                      return "white";
                    }
                  },
                  "&:hover": { color: "#ffc717" },
                  display: "block",
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
