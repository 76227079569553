import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

export default function ProductDataTable({ product }) {
  return (
    <div style={{ height: 400, width: "100%", marginTop: 10 }}>
      {product && product.table_info && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {product.table_info.columns.map((product, index) => {
                  return (
                    <TableCell key={index} align="center">
                      <Typography variant="caption" fontWeight="bold">
                        {product.headerName}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {product.table_info.rows.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row.mfg && (
                    <TableCell align="center">
                      <Typography variant="caption">{row.mfg}</Typography>
                    </TableCell>
                  )}
                  {row.upc && (
                    <TableCell align="center">
                      <Typography variant="caption">{row.upc}</Typography>
                    </TableCell>
                  )}
                  {row.weight && (
                    <TableCell align="center">
                      <Typography variant="caption">{row.weight}</Typography>
                    </TableCell>
                  )}
                  {row.coverage && (
                    <TableCell align="center">
                      <Typography variant="caption">{row.coverage}</Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
