import { Grid, Typography, Box, Stack, Container } from "@mui/material";
import ImageBox from "../components/imagebox/Responsive_Logo";
import EducationHeader from "../assets/images/Education.webp";
import Divider from "../components/divider/Divider";
import EducationCard from "../components/card/EducationCard";
import IceMeltFAQ from "../assets/images/Ice_Melt_FAQ.webp";
import Fertilizer_FAQ from "../assets/images/Fertilizer_FAQ.webp";
import InTheBag from "../assets/images/WhatsInTheBag.webp";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import sc2 from "../assets/images/ran/SC2.jpg";

const correct_word = "Starcraft2";

const more_education = [
  {
    title: "Ice Melt FAQs",
    description:
      "Is ice melt (potassium chloride) hazardous? How do I dispose of it? As with many...",
    image: IceMeltFAQ,
    url: "/Ice-Melt-FAQ",
  },
  {
    title: "Fertilizer FAQs",
    description:
      "How exactly do I fertilize my lawn? There are actually 3 parts to this answer...",
    image: Fertilizer_FAQ,
    url: "/Fertilizer-FAQ",
  },
  {
    title: "Understanding What's In The Bag",
    description:
      "The best way for you to know what kind of fertilizer you need is by understanding what... ",
    image: InTheBag,
    url: "/Understanding-Whats-In-The-Bag",
  },
];

const Education = () => {
  let navigate = useNavigate();
  const [word, setWord] = useState("");

  const ee = (letter) => {
    let wordCopy = word + letter;
    let wordCopyLength = wordCopy.length;

    let compare_correct_word =
      correct_word.substring(0, wordCopyLength) === wordCopy;

    if (compare_correct_word) {
      console.log(wordCopy);
      if (wordCopy === correct_word) {
        window.open(
          "https://www.youtube.com/watch?v=stfsw3uVvjc&ab_channel=StarCraftBattleArchive"
        );
        window.open(sc2);
      } else {
        setWord(wordCopy);
      }
    } else {
      setWord("");
    }
  };

  return (
    <Grid container sx={{ marginTop: "0" }}>
      <Grid item xs={12} position="relative">
        <Box
          position="absolute"
          top={{ xs: 0, sm: 25, md: 30, lg: 75 }}
          left={{ xs: "33%", sm: "42%", md: 50 }}
        >
          <Typography
            sx={{ color: "white", display: { xs: "none", md: "block" } }}
            variant="h4"
            fontWeight="bold"
          >
            Learn More About Our Products
          </Typography>
        </Box>

        <ImageBox src={EducationHeader} width="100%" />
      </Grid>

      <Grid item xs={12}>
        <Container>
          <Stack mt={{ xs: 0, sm: 5 }} spacing={2}>
            <Typography sx={{ typography: { xs: "h6", sm: "h4" } }}>
              Applying Our Fertilizers – Generic{" "}
              <span
                onClick={() => {
                  ee("S");
                }}
              >
                S
              </span>
              preader Setting Chart
            </Typography>

            <Typography variant="body1">
              The following chart is meant to provide general guidance when an
              actual setting is not available. The age and condition of the
              spreader may af
              <span
                onClick={() => {
                  ee("f");
                }}
              >
                f
              </span>
              ect the flow of product. The spreader may need to be calibrated to
              get proper coverage.
            </Typography>

            <Typography variant="h6">
              To{" "}
              <span
                onClick={() => {
                  ee("c");
                }}
              >
                c
              </span>
              alibrate your spreader:
            </Typography>

            <Box>
              <Stack ml={3}>
                <Typography>
                  1. Determine the amount of fertilizer needed for 100 square
                  feet.
                </Typography>
                <Typography>
                  2. Divide the number of square feet the bag of fertilizer
                  covers by 100.
                </Typography>
                <Typography>
                  3. Divide the weight of the bag by this number.
                </Typography>
                <Typography>
                  4. The answer is the amount of fertilizer needed to cover 100
                  square feet.
                </Typography>
                <Typography>
                  5. Pl
                  <span
                    onClick={() => {
                      ee("a");
                    }}
                  >
                    a
                  </span>
                  ce this amount in the spreader.
                </Typography>
                <Typography>6. Mark off a 10-foot by 10-foot area.</Typography>
                <Typography>7. Apply using the suggested setting.</Typography>
              </Stack>
            </Box>

            <Typography variant="h6">For example: </Typography>

            <Typography fontStyle="italic">
              The fertilizer bag weighs{" "}
              <span
                onClick={() => {
                  ee("2");
                }}
              >
                2
              </span>
              5 lbs. and covers 5,000 sq. ft.
            </Typography>

            <Typography fontStyle="italic">
              5,000 divided by 100 = 50
            </Typography>

            <Typography fontStyle="italic">
              25 lbs. divided by 50 = .5 or 1/2 lb. of product needed to cover
              100 sq. ft.
            </Typography>

            <Typography fontStyle="italic">
              If product runs out before completing the area, decrease the
              setting. Mark off another area the same size and repeat the test
              until the correct application rate is achieved.
            </Typography>

            <Typography fontStyle="italic">
              If product{" "}
              <span
                onClick={() => {
                  ee("r");
                }}
              >
                r
              </span>
              emains in the spreader, increase the setting. Mark off another
              area the same size and repeat the test until the correc
              <span
                onClick={() => {
                  ee("t");
                }}
              >
                t
              </span>{" "}
              application rate is achieved.
            </Typography>

            <Typography fontStyle="italic">
              *pounds per 1,000 square feet
            </Typography>

            <Divider />
          </Stack>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h4" align="center" mt={5}>
          More
        </Typography>
      </Grid>

      <Grid item container xs={12} justifyContent="center">
        {more_education.map((doc, index) => {
          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={5}
              md={3}
              p={2}
              onClick={() => {
                navigate(doc.url);
              }}
            >
              <EducationCard
                image={doc.image}
                description={doc.description}
                title={doc.title}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Education;
