import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ImageBox = ({ src, width, height, r_xs, r_md }) => {
  let navigate = useNavigate();

  return (
    <Box
      component="img"
      sx={{
        height: height,
        width: width,
        display: { xs: r_xs, md: r_md },
        cursor: "pointer",
      }}
      onClick={() => navigate("/")}
      alt="Bruce Oakley Inc Logo"
      src={src}
    />
  );
};

export default ImageBox;
